/* for using helper file in you vue file use this

[component].vue

<template>
  <p>{{formatNumber(total, 0)}}</p>
</template>

<script>
import helper from '@/utils/helper-mixin.js'

export default {
  name: 'Example',
  mixins: [helper]
}
</script>

*/

import helper from "@/utils/helper.js";

export default {
  methods: {
    // склонение по числам
    num2str: (n, text_forms) => {
      return helper.num2str(n, text_forms);
    }
  }
};
