<!--TODO: add expanded rows like this https://codepen.io/francobao/pen/mqxMKP  -->
<template>
  <v-container>
    <v-data-table
      :headers="headers[headerType]"
      :items="filteredTransactions"
      :sort-by="['date']"
      class="elevation-1"
      :disable-pagination="sheetView ? true : false"
      :hide-default-footer="sheetView ? true : false"
      :single-expand="true"
      :expanded.sync="expanded"
      :search="searchQuery"
      item-key="id"
      show-expand
      v-if="loaded == 100"
    >
      <!--
        :search="search"
        :custom-filter="filterTransactions"
      -->
      <!---template v-slot:top>
          <v-text-field v-model="search" label="Поиск" class="mx-8"></v-text-field>
        </template-->

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            append-icon="mdi-magnify"
            class="d-flex"
            label="Найти"
            single-line
            hide-details
            v-model="searchQueryEntering"
          ></v-text-field>
          <v-icon class="d-flex pl-2" @click.prevent="reload()"
            >mdi-refresh</v-icon
          >
          <v-menu v-if="filters != null && filters.length > 0">
            <template v-slot:activator="{ on: menu }">
              <v-chip
                class="ma-2"
                color="dark grey"
                outlined
                v-on="{ ...menu }"
              >
                {{ selectedFilter.display }}
              </v-chip>
            </template>
            <v-list>
              <v-list-item
                v-for="(filter, index) in filters"
                :key="index"
                @click="selectedFilter = filter"
              >
                <v-list-item-title>{{ filter.display }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card
          class="mx-auto text-center"
          :color="forecastValues.find(i=>i<0)?'red':'green'"
          dark
          v-if="selectedFilter.value == 'forecast'"
        >
          <!-- <v-card-title>
            Прогноз
          </v-card-title> -->
          <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
              <v-sparkline
                :value="forecastValues"
                color="rgba(255, 255, 255, .7)"
                height="100"
                padding="24"
                :line-width="2"
                :smooth="true"
                stroke-linecap="round"
              >
                <template v-slot:label="item"> {{ 
                    (item.index%(Math.round(forecastValues.length/5))==0 || item.index==forecastValues.length-1)?formatAmountEx(item.value, true):''
                  }} </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text class="d-none d-md-block">
            <div class="display-1 font-weight-thin">Прогноз с сегодняшнего дня</div>
          </v-card-text>

          <v-divider></v-divider>

          <!-- <v-card-subtitile>
            Параметры прогноза
          </v-card-subtitile> -->

          <v-card-text>
            <v-combobox
              v-model="selectedTags"
              :items="tags"
              chips
              clearable
              label="Теги обязательные в договорах прогнозных транзакций"
              multiple
              solo
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  class="ma-2"
                  label
                  close
                  :input-value="selected"
                  color="blue"
                  text-color="white"
                  @click="select"
                  @click:close="removeTag(item, selectedTags)"
                  small
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>

            <!-- Теги исключения в договорах прогнозных транзакций -->
            <v-combobox
              v-model="excludeTags"
              :items="tags"
              chips
              clearable
              label="Теги исключения в договорах прогнозных транзакций"
              multiple
              solo
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  class="ma-2"
                  label
                  close
                  :input-value="selected"
                  color="red"
                  text-color="white"
                  @click="select"
                  @click:close="removeTag(item, excludeTags)"
                  small
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>

            <v-slider
              v-model="allowedDeviationPersantage"
              label="Сглаживание"
            ></v-slider>
          </v-card-text>

          <!-- <v-card-actions class="justify-center">
            <v-btn block text>Обновить</v-btn>
          </v-card-actions> -->
        </v-card>
      </template>

      <template v-slot:item.date="{ item }">
        <!-- Any warnings for the item -->
        <v-tooltip bottom v-if="item.extra && item.extra.warnings">
          <template v-slot:activator="{ on }">
            <v-sheet
              class="white--text"
              v-on="on"
              :style="{
                cursor: 'initial',
                background: !!item.forcast ? 'orange' : 'red',
                paddingLeft: '5px',
                paddingRight: '5px',
              }"
              a
            >
              {{ getDisplayDate(item.date) }}
            </v-sheet>
          </template>
          <span>{{ item.extra.warning_text }}</span>
        </v-tooltip>
        <v-sheet
          color="transparent"
          v-on="on"
          style="cursor: initial; padding-left: 5px; padding-right: 5px"
          v-else
        >
          {{ getDisplayDate(item.date) }}
        </v-sheet>
      </template>
      <!-- Сумма -->
      <template v-slot:item.amount="{ item }" v-if="useChip">
        <v-chip class="mt-1" :color="getColor(item.amount)" dark>
          {{ formatAmount(item.amount) }}
        </v-chip>
      </template>
      <template v-slot:item.amount="{ item }" v-else>
        <!-- v-else for useChip -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="
                getColor(item.amount) +
                '--text text--darken-2 font-weight-medium ' +
                (!item.forcast || 'font-italic')
              "
              v-bind="attrs"
              v-on="on"
            >
              {{ formatAmount(item.amount) }}
            </div>
          </template>
          <span>{{ item.description }}</span>
        </v-tooltip>
      </template>
      <!-- Подитог (EBITDA) -->
      <template v-slot:item.subtotal_ebitda="{ item }">
        <div :class="getColor(item.subtotal_ebitda) + '--text text--darken-2'">
          {{ formatAmount(item.subtotal_ebitda) }}
        </div>
      </template>
      <!-- Формула -->
      <template v-slot:item.formula="{ item }">
        <v-icon style="color: red">mdi-alert-box</v-icon>
      </template>
      <!-- Налоговая нагрузка -->
      <template v-slot:item.payloadtax="{ item }">
        <div :class="getColor(item.payloadtax) + '--text text--darken-2'">
          {{ item.payloadtax ? formatAmount(item.payloadtax) : "-" }}
        </div>
      </template>
      <!-- Комиссионная нагрузка -->
      <template v-slot:item.payloadcomission="{ item }">
        <div :class="getColor(item.payloadcomission) + '--text text--darken-2'">
          {{
            item.payloadcomission ? formatAmount(item.payloadcomission) : "-"
          }}
        </div>
      </template>
      <!-- Статус -->
      <template v-slot:item.status="{ item }">
        <!-- Calculcation warning -->
        <v-tooltip bottom v-if="item.calculation_result.warnings > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon style="color: red; cursor: initial" v-bind="attrs" v-on="on"
              >mdi-alert-box</v-icon
            >
          </template>
          <span>Возникли проблемы при расчете формул</span>
        </v-tooltip>
        <!-- Output status -->
        <div v-if="item.resolution == 'confirmed'">проведено</div>
        <div v-else>
          {{ item.resolution }}
        </div>
      </template>
      <!-- Подитог -->
      <template v-slot:item.subtotal="{ item }">
        <div :class="getColor(item.subtotal) + '--text text--darken-2'">
          {{ formatAmount(item.subtotal) }}
        </div>
      </template>
      <!-- Договор -->
      <template v-slot:item.contract.title="{ item }">
        <router-link
          class="font-weight-regular"
          style="text-decoration: none"
          :to="{ name: 'contract', params: { id: item.contract._id } }"
          >{{ item.contract.title }}</router-link
        >
        <!--v-btn
                text
                color="primary"
                :to="{ name: 'contract', params: { id: item.contract._id } }"
                small>{{ item.contract.title }}</v-btn
              -->
      </template>
      <!-- Действия -->
      <template v-slot:item.actions="{ item }">
        <!-- если операция подтврждена, то еще можно сделать ей undo, трижды нажав на галочку -->
        <v-icon
          style="color: green"
          v-if="item.resolution == 'confirmed'"
          @click="undoItemEnabled.push(item._id)"
          >mdi-checkbox-marked-outline</v-icon
        >
        <v-tooltip bottom v-if="!item.resolution">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.stop="confirm(item)"
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-checkbox-marked-outline</v-icon>
            </v-btn>
          </template>
          <span>Подтвердить транзакцию</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            !item.resolution ||
            undoItemEnabled.filter((x) => x == item._id).length > 2
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.stop="decline(item)"
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
          <span>Убрать транзакцию</span>
        </v-tooltip>
      </template>
      <!-- https://codepen.io/nekosaur/pen/MWgzXmO?editors=1010 -->
      <!-- Весы -->
      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <v-icon
          @click="expand(!isExpanded)"
          v-if="item.id == today_totals.id_expanded"
          >mdi-scale-balance</v-icon
        >
      </template>
      <!-- <template v-slot:prepend="{ expand }"> 
          {{ expand(2, true) }}
        </template> -->
      <!-- Подитоги -->
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="2">
          <div class="text--darken-2 font-weight-medium">Итого на сегодня:</div>
        </td>
        <!-- <td :colspan="1">
          <v-edit-dialog
            :return-value.sync="new_total_ebitda"
            @save="saveNewTotalEbitda"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            <div
              :class="
                getColor(today_totals.subtotal_ebitda) +
                '--text text--darken-2 text-right'
              "
            >
              {{ formatAmount(today_totals.subtotal_ebitda) }}
            </div>
            <template v-slot:input>
              <v-text-field
                v-model="new_total_ebitda"
                :rules="[amountValidation]"
                label="Edit"
                single-line
                :autofocus="true"
                counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </td> -->
        <!-- <td :colspan="1">
          <div
            :class="
              getColor(today_totals.subtotal_taxes) +
              '--text text--darken-2 text-right'
            "
          >
            {{ formatAmount(today_totals.subtotal_taxes) }}
          </div>
        </td> -->
        <!-- <td :colspan="1" :class="align - right">
          <div
            :class="
              getColor(today_totals.subtotal_comission) +
              '--text text--darken-2 text-right'
            "
          >
            {{ formatAmount(today_totals.subtotal_comission) }}
          </div>
        </td> -->
        <td :colspan="1" :class="align - right">
          <div
            :class="
              getColor(today_totals.subtotal) +
              '--text text--darken-2 text-right'
            "
          >
            {{ formatAmount(today_totals.subtotal) }}
          </div>
        </td>

        <td :colspan="2">
          <v-edit-dialog
            :return-value.sync="new_total_ebitda"
            @save="saveNewSubtotal"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="new_total_ebitda = today_totals.subtotal"
                >
                  <v-icon dark color="primary darken-2"
                    >mdi-circle-edit-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Добавить корректирующую транзакцию</span>
            </v-tooltip>
            <template v-slot:input>
              <v-text-field
                v-model="new_total_ebitda"
                :rules="[amountValidation]"
                label="Введите новое значение подитога"
                autofocus
                counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </td>
        <td :colspan="2"></td>
      </template>
    </v-data-table>
    <v-progress-linear :value="loaded" v-else></v-progress-linear>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { stringify } from "query-string";
import moment from "moment";
import Helper from "@/utils/helper-mixin.js";
import Contract from "../views/Contract.vue";

const API_URL_TRX = process.env.VUE_APP_BASE_API_URL + "/trx";

export default {
  name: "list-transaction",
  components: { Contract },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    // отображать колбасками (true) или по-простому (false)
    useChip: {
      type: Boolean,
      default: true,
    },
    headerType: {
      type: String,
      default: "short",
    },
    // table look likes a long sheet
    sheetView: {
      type: Boolean,
      default: false,
    },
    // дополнительный путь в запросе при чтении транзаций
    // (например, нужен для модификации запроса с целью отображения всех транзакций)
    // для отображения всех транзакций надо задать значение subQueryUrl="/display"
    subQueryUrl: {
      type: String,
      default: "",
    },
    // // минимальная сумма транзакции, отображаемая в списке
    // filterMinAmount: {
    //   type: Number,
    //   default: 0,
    // },
    // отфильтровать только важные операции
    filterOnlyImportant: {
      type: Boolean,
      default: false,
    },
    // внешний параметр опредеяет список тегов, по которым фильруются договоры
    filterTags: {
      type: Array,
      default: "", // пустое значение определяет, что теги не заданы
    },
    // function calls when any data on tables has been updated
    onDataUpdated: {
      type: Function,
    },
    reloadRequested: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    transactions: [],
    filterMinAmount: NaN,
    filters: [
      { display: "Все транзакции", value: "all" },
      { display: "Только важные", value: "important" },
      { display: "Прогноз", value: "forecast" },
    ],
    tags: [],
    selectedTags: [],
    excludeTags: [],
    // !!! в create вроде это заполняется еще раз, но create как-то не успевает за загрузкой mount иногда и грузятся все транзакции вместо только важных
    // !!! поэтому тут оставлено только важные с самого начала. Хорошо бы как-то иначе переделать
    selectedFilter: { display: "Только важные", value: "important" },
    today_totals: {
      subtotal: 0,
      subtotal_ebitda: 0,
      subtotal_taxes: 0,
      subtotal_comission: 0,
      id_expanded: -1,
    },
    new_total_ebitda: 0, // для редактирования
    today_widget_totals: {
      any_problems: false,
      subtotal: 0,
      subtotal_ebitda: 0,
    },
    // filters: {
    //   description: "",
    //   from: null,
    //   to: null,
    // },
    searchQuery: "",
    searchQueryEntering: "", // accumulation of entering query
    queryTimeoutHandler: null,
    expanded: [],
    singleExpand: false,
    //editDateDialog: {},
    undoItemEnabled: [],
    snack: false,
    snackColor: "",
    snackText: "",
    max25chars: (v) =>
      v.length <= 80 || "Сократите текст: максимально 80 символов",
    amountValidation: (v) =>
      /[-+]?\d{1,10}(\.\d{1,2})?/.test(v) || "Должна быть сумма",
    pagination: {},
    loaded: 0, // 0-100, где 100 загружено полностью
    addNewEventMode: false,
    forecastValues: [],
    allowedDeviationPersantage: 1, // чем больше значение, тем график больше будет съедать отклонений и будет более плавным
    // two kinds of headers (short and full)
    // full is going with contract information
    headers: {
      short: [
        {
          text: "Дата",
          align: "left",
          sortable: true,
          value: "date",
          width: "150px",
        },
        {
          text: "Сумма",
          align: "left",
          sortable: false,
          value: "amount",
          width: "150px",
        },
        {
          text: "Описание",
          align: "left",
          sortable: false,
          value: "description",
        },
        {
          text: "Действия", // действия доступны только в коротком режиме отображения, хотя можно добавить и в полной (full)
          align: "right",
          sortable: false,
          value: "actions",
        },
      ],
      full: [
        {
          text: "Дата",
          align: "left",
          sortable: false,
          value: "date",
          width: "100px",
        },
        {
          text: "Сумма",
          align: "right",
          sortable: false,
          value: "amount",
          width: "150px",
        },
        // {
        //   text: "EBITDA",
        //   align: "right",
        //   sortable: false,
        //   value: "subtotal_ebitda",
        //   width: "120px",
        // },
        // {
        //   text: "Налоги",
        //   align: "right",
        //   sortable: false,
        //   value: "payloadtax",
        //   width: "120px",
        // },
        // {
        //   text: "Комиссия",
        //   align: "right",
        //   sortable: false,
        //   value: "payloadcomission",
        //   width: "120px",
        // },
        {
          text: "Подитог",
          align: "right",
          sortable: false,
          value: "subtotal",
          width: "150px",
        },
        {
          text: "Статус",
          align: "center",
          sortable: false,
          value: "status",
          width: "150px",
        },
        {
          text: "Договор",
          align: "left",
          sortable: false,
          value: "contract.title",
        },
        // выпадающий элемент таблицы с итогами
        {
          text: "",
          value: "data-table-expand",
        },
      ],
    },
  }),
  methods: {
    getDisplayDate(d) {
      return moment(d).format("DD/MM/YYYY");
      // return new Date(d).toLocaleDateString(undefined, {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit"
      // });
    },
    emit(eventName, value) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
    confirm(item) {
      // eslint-disable-next-line no-console
      console.log(`confirm(${item.id}`);
      // changes resolution and save
      item.resolution = "confirmed";
      this.save(item);
    },
    update(trx) {
      // eslint-disable-next-line no-console
      console.log(`onUpdateTrxItem(${JSON.stringify(trx)})`);
      // link to current object
      trx.obj = this.$route.params.id;
      // make post request
      return fetch(API_URL_TRX, {
        method: "POST",
        body: JSON.stringify(trx),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log("response is ", result);
          // add new message on a page
          if (result.details == null) {
            // is this update already exists object
            var trxUpdated = this.transactions.find(
              (x) => x._id && x._id == result._id
            );
            // eslint-disable-next-line no-console
            console.log("trxUpdated is", trxUpdated || "n/a");
            // is anything to be updated?
            if (trxUpdated) {
              // eslint-disable-next-line no-console
              console.log("updating existed item");
              // update
              trxUpdated = result.details; // yes!
            } else {
              // nothing to update, so this trx is new one
              // eslint-disable-next-line no-console
              console.log("add new item");
              // add
              this.transactions.push(result);
              return result;
            }
            // this.item = result;
            // finish editing
            // this.itemEditing.customerrate = false;
          }
        });
    },
    updateTotals(new_total_ebitda) {
      console.log(`updateTotals(${JSON.stringify(new_total_ebitda)})`);
      // обновляется только subttoal_ebitda
      return this.$http
        .post(API_URL_TRX + "/subtotal", {
          subtotal: this.today_totals.subtotal,
          new_subtotal: Number.parseFloat(new_total_ebitda),
        })
        .then((response) => {
          console.log("response is ", response);
        })
        .catch((error) => {
          console.log("error is ", error);
          if (error.response != null) throw error.response.data;
        });
    },
    //!!! кривая херня с загрузкой.. onloaded вызывается несколько раз в процессе загрузки... 4-5 раз.. в результате запускются 4-5 таймеров... надо бы переписатьы
    loading() {
      this.loaded = Math.min(
        100,
        this.loaded + Math.round(Math.random() * 10) + 1
      );
      console.log("tick! ", this.loaded);
      if (this.loaded < 100) setTimeout(this.loading, 150);
    },
    onLoaded() {
      console.log("onLoaded");
      setTimeout(this.loading, 150);
    },
    reload() {
      // eslint-disable-next-line no-console
      console.log("(re)load transactions");
      const params = {
        filter: this.selectedFilter.value,
        forecastTags: this.selectedFilter.value == "forecast"?this.selectedTags:[],
        excludeTags: this.selectedFilter.value == "forecast"?this.excludeTags:[]
        //   this.selectedFilter.value == "forecast"
        //     ? Array.from(this.selectedTags).join(",")
        //     : "", // задает допустимые теги для прогноза
        // excludeTags: this.selectedFilter.value == "forecast"
        //     ? Array.from(this.excludeTags).join(",")
        //     : "", // задает теги исключения из прогноза

      };
      fetch(`${API_URL_TRX}${this.subQueryUrl}?${stringify(params)}`)
        .then((resp) => resp.json())
        .then((resp_json) => {
          // eslint-disable-next-line no-console
          console.log(resp_json);
          //~~~this.transactions.push.apply(this.transactions, resp_json);
          if (resp_json.hasOwnProperty("trxs")) {
            //this.transactions = resp_json.trxs;
            this.transactions = []; // clear
            this.transactions.push.apply(this.transactions, resp_json.trxs);
            this.today_totals = resp_json.today_totals;
            this.today_widget_totals = resp_json.today_widget_totals;
            this.tags = resp_json.today_widget_totals.tags
              ? (Array.isArray(resp_json.today_widget_totals.tags) ? resp_json.today_widget_totals.tags : resp_json.today_widget_totals.tags.split(/,/))
              : [];
            this.new_total_ebitda = resp_json.today_totals.subtotal_ebitda;
            
            // подготовка данных для графа прогноза
            let forecastLine = this.transactions.map(t=>t.subtotal);
            let precision = Math.round(forecastLine.length/5); // todo 5 to params!! look above too
            forecastLine = forecastLine.reduce((acc,val,index,array) => {
              // в обычном порядке пропускаются все значения между точками точности
              if (index%precision != 0 && index!=array.length-1) {
                // но, если есть какие-то очень важные отклонения, например, на 10% от предыдущего значения
                // то такие значения все же остаются на графе! а все значения с отклонением меньше 10%
                // просто пропускаются
                if (index > 0 && Math.abs((array[index-1]/val-1)*100) < this.allowedDeviationPersantage) return acc;
              }
              return acc.concat(val)
            }, []);
            this.forecastValues = forecastLine;
            
            this.expanded = [];
          } else {
            //this.transactions = resp_json;
            this.transactions.push.apply(this.transactions, resp_json);
          }
          this.onLoaded();
        });
    },
    decline(item) {
      // eslint-disable-next-line no-console
      console.log(`decline(${item.id}`);
      // changes resolution and save
      item.resolution = "declined";
      this.save(item);
    },
    add() {
      // eslint-disable-next-line no-console
      console.log("Add new trx");
      // eslint-disable-next-line no-console
      console.log("Add new trx");
      let newTrx = {
        date: new Date().toISOString(),
        amount: 0,
        description: "<новая транзакция>",
      };
      this.update(newTrx).then((item) => {
        //this.editDateDialog[item._id] = false;
      });
    },
    saveNewSubtotal() {
      this.updateTotals(this.new_total_ebitda)
        .then(() => {
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Сохранено!";
          this.reload();
        })
        .catch((error) => {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = error;
          // reset input value
          this.new_total_ebitda = today_totals.subtotal_ebitda;
        });
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Отмена";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Редактируем";
    },
    close() {
      // eslint-disable-next-line no-console
      console.log("Dialog closed");
    },
    getColor(amount) {
      if (amount < 0) return "red";
      else if (amount > 100000) return "green";
      else return "orange";
    },
    /**
     * Форматирование суммы с возможностью округления и представления в тысячных 'K'
     * @param {boolean} isK - тысячныы да или нет
     */
    formatAmountEx(value, isK) {
      let postfix = '';
      let tail = '';
      if (isK == true) {
        value = Math.round(value/1000);
        postfix = 'K'; // ₽
        tail = ',00';
      }
      return this.formatAmount(value).replace(tail, postfix);
    },
    formatAmount(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      let valFormated = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return value > 0 ? "+" + valFormated : valFormated;
    },
    onCancelEditEvent() {
      // eslint-disable-next-line no-console
      console.log(`onCancelEditEvent()`);
      //
      this.selectedEvent = -1;
    },
    removeTag (item, tags) {
      tags.splice(tags.indexOf(item), 1)
      tags = [...tags]
    },    
    runInlineQuery() {
      //!!! rewrite to custom filter!!
      if (this.searchQueryEntering.startsWith(">")) {
        this.filterMinAmount = parseInt(
          this.searchQueryEntering.substring(1).trim()
        );
        this.searchQuery = "";
      } else {
        this.filterMinAmount = 0;
        this.searchQuery = this.searchQueryEntering;
      }
    },
    // ,
    // filterTransactions(value, search, item) {
    //   return (
    //     value != null &&
    //     item != null &&
    //     search != null &&
    //     typeof value === "string" &&
    //     value
    //       .toString()
    //       .toLocaleUpperCase()
    //       .indexOf(search.toLocaleUpperCase()) !== -1
    //   );
    // }
  },
  watch: {
    filterOnlyImportant: {
      //!!! tobe deleted
      immediate: true,
      handler(val, oldVal) {
        this.reload();
      },
    },
    selectedFilter: {
      immediate: true,
      handler(val, oldVal) {
        this.reload(); //!!! надо сделать мягую перезагрузку с отсрочкой на 3 секунды, чтобы успеть что-то еще набрать..
      },
    },
    selectedTags: {
      immediate: true,
      handler(val, oldVal) {
        this.reload(); //!!! надо сделать мягую перезагрузку с отсрочкой на 3 секунды, чтобы успеть что-то еще набрать..
      },
    },
    excludeTags: {
      immediate: true,
      handler(val, oldVal) {
        this.reload(); //!!! надо сделать мягую перезагрузку с отсрочкой на 3 секунды, чтобы успеть что-то еще набрать..
      },
    },
    searchQueryEntering: {
      immediate: true,
      handler(val, oldVal) {
        window.clearTimeout(this.queryTimeoutHandler);
        this.queryTimeoutHandler = setTimeout(this.runInlineQuery, 1000);
      },
    },
    today_totals: {
      immediate: true,
      handler(val, oldVal) {
        if (this.onDataUpdated !== undefined) {
          this.onDataUpdated(this.today_totals, this.today_widget_totals);
        }
      },
    },
    today_widget_totals: {
      immediate: true,
      handler(val, oldVal) {
        if (this.onDataUpdated !== undefined) {
          this.onDataUpdated(this.today_totals, this.today_widget_totals);
        }
      },
    },
    reloadRequested: {
      immediate: true,
      handler(val, oldVal) {
        this.reload();
      },
    },
  },
  mixins: [Helper],
  mounted() {
    this.reload();
  },
  created() {
    /*???
    this.transactions.map(x => {
      this.editDateDialog[x._id] = false;
      // eslint-disable-next-line no-console
      console.log("prep calendal " + x._id);
    });
    */
    this.selectedFilter = this.filters[1];
  },
  computed: {
    filteredTransactions() {
      let minAmount = Number(this.filterMinAmount);
      return this.transactions.filter((x) => Math.abs(x.amount) >= minAmount);
    },
    testDateDisplay() {
      if (this.testDate == null) return "n/a";
      return this.testDate;

      /*
      toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });*/
    },
  },
};
</script>
