<template>
  <v-container>
    <v-row>
      <v-col cols="11">
        <h1 class="headline">
          {{ item.title }}
          <Status
            :statusEnumeration="statusEnumeration"
            entity="contract"
            :readonly="item.security_owner !== user._id && !user.is_admin"
          />
        </h1>
      </v-col>
      <v-col cols="1" class="d-flex justify-end">
        <Security :item="item" entity="contract" />
      </v-col>
    </v-row>
    
    <!-- <v-row class="pt-2">
      <v-rating
        :value="4.5"
        color="green"
        dense
        half-increments
        readonly
        size="28"
      ></v-rating>
      <div class="grey--text ml-4">4.5 (413)</div>
    </v-row> -->
    <!-- <v-container> -->

    <v-row v-if="!item">
      <v-progress-linear :buffer-value="bufferValue"></v-progress-linear>
    </v-row>

    <v-row
      v-else-if="
        !item.security_owner ||
        item.security_owner === user._id ||
        user.is_admin
      "
    >
      <!-- Свойства -->
      <EntityPropertiesEdit
        :save="update"
        :entity="item"
        :properties="config.entities.find((i) => i.name === 'contract').fields"
      />
      <!-- Транзакции -->
      <ListTransactionsEdit :sheetView="true" :formula="item.formula" />
      <!-- Файлы -->
      <FileBox />
      <EventList :eventsGroups="eventsGroups" :editable="true" />
      <!-- </v-container> -->
    </v-row>

    <v-row v-else>
      <v-container>
        <v-alert border="bottom" colored-border type="warning" elevation="2">
          Для получения доступа к данной части портала обратитесь к своему руководителю
        </v-alert>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { stringify } from "query-string";
import EventList from "@/components/EventsList.vue";
import ListTransactionsEdit from "@/components/ListTransactionsEdit.vue";
import Status from "@/components/Status.vue";
import FileBox from "@/components/FileBox.vue";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import Security from "@/components/Security.vue";

const API_URL = process.env.VUE_APP_BASE_API_URL + "/contract";

export default {
  name: "contract",
  components: {
    ListTransactionsEdit,
    Status,
    FileBox,
    EventList,
    EntityPropertiesEdit,
    Security,
  },
  data: () => ({
    statusEnumeration: [
      {
        name: "new",
        display: "Новый",
        color: "dark blue blue--text text--darken-1",
      },
      {
        name: "signed",
        display: "Подписано",
        color: "dark green green--text text--darken-1",
      },
      {
        name: "archived",
        display: "В архиве",
        color: "dark grey grey--text text--darken-1",
      },
    ],
    /*
      title: Joi.string(),
      dateStart: Joi.date(),
      dateFinish: Joi.date(),
      payloadTax: Joi.string(), // формула расчета нагрузки налогов (любых)
      payloadComission: Joi.string(), // формула расчета нагрузки комиссионных (любых)    
    */
    itemProperties: [
      {
        icon: "mdi-file-document",
        caption: "Наименование договора",
        placeholder: "Наименование не указано",
        name: "title",
        type: "string",
        hint: "hint",
        message: "Введите наименование договора",
      },
      {
        icon: "mdi-calendar",
        caption: "Дата начала действия договора",
        placeholder: "Дата начала действия договора не указана",
        name: "datestart",
        type: "string",
        hint: "hint",
        message: "Введите дату начала действия договора",
      },
      {
        icon: "mdi-calendar",
        caption: "Дата окончания действия договора",
        placeholder: "Дата окончания действия договора не указана",
        name: "dateend",
        type: "string",
        hint: "hint",
        message: "Введите дату окончания действия договора",
      }/*,
      {
        icon: "mdi-calculator",
        caption: "Налоговая нагрузка (формула)",
        placeholder: "Налоговая нагрузка не определена",
        name: "payloadtax",
        type: "string",
        hint: "hint",
        message: "Введите формулу налоговой нагрузки для договора",
      },
      {
        icon: "mdi-calculator",
        caption: "Комиссионная нагрузка (формула)",
        placeholder: "Комиссионная нагрузка не определена",
        name: "payloadcomission",
        type: "string",
        hint: "hint",
        message: "Введите формулу комиссионной нагрузки для договора",
      },*/
    ],
    eventsGroups: [
      {
        name: "global",
        displayName: "Без даты",
        color: "blue lighten-5",
        filter: (x) => {
          //return !x.resolution || x.resolution == "confirmed";
          return true || x;
        },
      },
      {
        name: "yesterday",
        displayName: "Вчера",
        color: "red lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "today",
        displayName: "Сегодня",
        color: "teal lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "tomorrow",
        displayName: "Завтра",
        color: "lime lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
    ],
    transactions: [],
    item: null,
    itemold: {
      body: "",
      name: "",
      customer: "",
      project: "",
      location: "",
      position: "",
      customerrate: "",
      status: "",
      title: "",
    },
    itemEditing: {
      customer: false,
      project: false,
      location: false,
      position: false,
      customerrate: false,
      status: false,
      title: false,
    },
  }),
  computed: {
    statusDisplay() {
      const d = this.statusEnumeration.find((x) => x.name == this.item.status);
      return d ? d.display : "Статус не определен";
    },
    statusColor() {
      const d = this.statusEnumeration.find((x) => x.name == this.item.status);
      return d ? d.color : "success";
    },
  },
  methods: {
    onCancelEditEvent() {
      // eslint-disable-next-line no-console
      console.log(`onCancelEditEvent()`);
      //
      this.selectedEvent = -1;
    },
    onSelectEvent(item) {
      // eslint-disable-next-line no-console
      console.log(`onSelectEvent(${JSON.stringify(item)})`);
      // check
      if (item.resolution != null) {
        // eslint-disable-next-line no-console
        console.log("Event already have solution");
        return;
      }
      // select this event to be edited
      this.eventSelected = item;
      // save selected index
      this.selectedEvent = item._id;
    },
    update(item) {
      // eslint-disable-next-line no-console
      console.log(`updateItem(${JSON.stringify(item)})`);
      // make post request
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(item),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log("response is ");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            // update item view
            this.item = result;
          }
        });
    },
    updateItem() {
      // eslint-disable-next-line no-console
      console.log(`updateItem(${JSON.stringify(this.item)})`);
      // make post request
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(this.item),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log("response is ");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            // update item view
            this.item = result;
            // finish editing
            this.itemEditing.customer = false;
            this.itemEditing.project = false;
            this.itemEditing.location = false;
            this.itemEditing.position = false;
            this.itemEditing.customerrate = false;
            this.itemEditing.status = false;
            this.itemEditing.title = false;
          }
        });
    },
  },

  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.$route.params.id);

    fetch(
      `${process.env.VUE_APP_BASE_API_URL}/contract?${stringify(
        this.$route.params
      )}`
    )
      .then((resp) => resp.json())
      .then((resp_json) => {
        // eslint-disable-next-line no-console
        console.log(resp_json);
        this.item = resp_json;
      });

    // eslint-disable-next-line no-console
    /*
    console.log("loaded transaction");
    fetch(
      `${process.env.VUE_APP_BASE_API_URL}/trx?${stringify(this.$route.params)}`
    )
      .then(resp => resp.json())
      .then(resp_json => {
        // eslint-disable-next-line no-console
        console.log(resp_json);
        this.transactions.push.apply(this.transactions, resp_json);
      });
      */
  }
};
</script>
