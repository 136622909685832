<template>
  <v-container fluid>
    <ListTransactions
      subQueryUrl="/display"
      headerType="full"
      :editable="false"
      :sheetView="true"
      :useChip="false"
      :filterMinAmount="filterMinAmount"
      :filterOnlyImportant="filterOnlyImportant"
      :reloadRequested="reloadRequested"
      :onDataUpdated="onDataUpdated"
      :filterTags="filterTags"
    />
    <v-snackbar v-model="snack" :timeout="10000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Понятно</v-btn>
    </v-snackbar>
    <v-navigation-drawer :mini-variant="miniVariant" app clipped right>
      <v-app-bar dense flat>
        <v-app-bar-nav-icon @click.stop="miniVariant = !miniVariant" />
      </v-app-bar>
      <!-- <v-list>
        <v-list-item class="px-2"> -->
      <v-card tile v-if="!miniVariant">
        <v-card-title>Подитоги</v-card-title>
        <!-- <v-card-subtitle>Сегодня</v-card-subtitle> -->
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Сегодня</v-list-item-title>
            <v-list-item-subtitle
              v-if="
                !today_widget_totals.any_problems ||
                today_widget_totals.any_problems == 0
              "
              >{{
                formatAmount(today_widget_totals.subtotal)
              }}</v-list-item-subtitle
            >
            <v-list-item-subtitle v-else>
              {{ today_widget_totals.any_problems }}
              {{
                num2str(today_widget_totals.any_problems, [
                  "проблема",
                  "проблемы",
                  "проблем",
                ])
              }}
              в транзакциях
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
<!-- 
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Следующий месяц</v-list-item-title>
            <v-list-item-subtitle>не определено</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Следующий квартал</v-list-item-title>
            <v-list-item-subtitle>не определено</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item> -->

        <v-card-text v-if="false">
          <v-chip
            class="d-block"
            :color="getColor(today_widget_totals.subtotal)"
            text-color="white"
            dense
            v-if="
              !today_widget_totals.any_problems ||
              today_widget_totals.any_problems == 0
            "
          >
            <v-avatar left>
              <v-icon>mdi-scale-balance</v-icon>
            </v-avatar>
            {{ formatAmount(today_widget_totals.subtotal) }}
          </v-chip>
          <!-- else -->
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-chip class="d-block" color="grey" text-color="white" v-on="on">
                {{ formatAmount(today_widget_totals.subtotal) }} *
              </v-chip>
            </template>
            <span
              >{{ today_widget_totals.any_problems }}
              {{
                num2str(today_widget_totals.any_problems, [
                  "проблема",
                  "проблемы",
                  "проблем",
                ])
              }}
              в транзакциях</span
            >
          </v-tooltip>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="forcast">Пересчитать прогноз</v-btn>
        </v-card-actions>

        <!-- <v-combobox
          v-model="selectedTags"
          :items="filterTags"
          chips
          clearable
          label="Your favorite hobbies"
          multiple
          prepend-icon="mdi-filter-variant"
          solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              class="ma-2"
              label
              close
              :input-value="selected"
              color="blue"
              text-color="white"
              @click="select"
              @click:close="removeTag(item)"
              small
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox> -->

        <!-- <v-chip
          class="ma-2"
          label
          v-for="(tag, index) in filterTags"
          :key="tag"
          color="blue"
          text-color="white"
          @click="switchFilterTag(index)"
          small
        >
          {{ tag }}
        </v-chip>   -->
      </v-card>

      <!--v-card tile v-if="!miniVariant"-->

      <!--v-list-item>
          <v-list-item-content>
              <v-text-field
                v-model="filterTags"
                label="теги"
                hide-details
                single-line
                type="string"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn text @click="apply">Применить</v-btn>
          </v-card-actions-->
      <!--/v-card-->
      <!-- </v-list-item> -->
      <!-- dense -->
      <!-- <v-list-item v-if="!miniVariant"> -->
      <!-- <v-list-item-avatar>
            <img src="https://randomuser.me/api/portraits/men/81.jpg" />
          </v-list-item-avatar> -->
      <!-- <v-list-item-content>
            <v-list-item-title>Сумма</v-list-item-title>
            <v-text-field
              v-model="filterMinAmount"
              label="минимальная сумма"
              hide-details
              single-line
              type="number"
            ></v-text-field>
          </v-list-item-content> -->
      <!-- </v-list-item> -->
      <!-- <v-list-item v-if="!miniVariant">
          <v-list-item-content>
            <v-list-item-title>Фильтр</v-list-item-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-switch
                  class="pl-3"
                  v-model="filterOnlyImportant"
                  label="Важные"
                  v-on="on"
                ></v-switch>
              </template>
              <span>-7..+30 дней + проблемные</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item> -->
      <!-- </v-list> -->
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import ListTransactions from "@/components/ListTransactionsView.vue";
import Helper from "@/utils/helper-mixin.js";

const API_URL_TRX = process.env.VUE_APP_BASE_API_URL + "/trx";

export default {
  components: {
    ListTransactions,
  },
  data: () => ({
    transactions: [],
    drawerRight: null,
    miniVariant: false,
    filterMinAmount: null,
    filterOnlyImportant: true,
    today_widget_totals: {
      subtotal: 0,
      any_problem: 0,
    },
    snack: false,
    snackColor: "",
    snackText: "",
    filterTags: [],
    selectedTags: [],
    reloadRequested: false,
  }),
  methods: {
    onDataUpdated(today_totals, today_widget_totals) {
      this.today_widget_totals = today_totals;
      this.filterTags = today_widget_totals.tags.split(/,/);
      //this.today_widget_totals = today_widget_totals;
    },
    getColor(amount) {
      //!!! to utils
      if (amount < 0) return "red";
      else if (amount > 100000) return "green";
      else return "orange";
    },
    formatAmount(value) {
      //!!! to utils
      let val = (value / 1).toFixed(2).replace(".", ",");
      let valFormated = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return value > 0 ? "+" + valFormated : valFormated;
    },
    switchFilterTag(index) {
      //this.filterTags[index] = this.filterTags[index].startsWith('-')?this.filterTags[index].substring(1,this.filterTags[index].length-1):'-'+this.filterTags[index];
      this.filterTags[index] = "HELLO";
    },
    /**
     * Строит прогноз по всем действующим договорам, в которых есть формула
     */
    forcast() {
      console.log("forcast()"); //!!!
      const params = {
        param1: "0",
      };
      return fetch(API_URL_TRX + "/forcast/all", {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          return response.ok
            ? response
            : response.text().then((text) => {
                throw Error(text);
              });
        }) // todo move to mixins
        .then((resp) => resp.json())
        .then((result) => {
          console.log("response is ", result);

          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Обработано контрактов " + result.length;

          this.reloadRequested = true;
        })
        .catch((error) => {
          console.log(`error ${error}`);
          // todo move to mixins
          this.snack = true;
          this.snackColor = "error";
          this.snackText = error;
        });
    },
    removeTag (item) {
      this.selectedTags.splice(this.chips.indexOf(item), 1)
      this.selectedTags = [...this.selectedTags]
    },    
    apply() {
      this.reloadRequested = !this.reloadRequested;
    },
  },
  mixins: [Helper],
  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.$route.params.id);
  },
};
</script>
