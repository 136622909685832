var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('ListTransactions',{attrs:{"subQueryUrl":"/display","headerType":"full","editable":false,"sheetView":true,"useChip":false,"filterMinAmount":_vm.filterMinAmount,"filterOnlyImportant":_vm.filterOnlyImportant,"reloadRequested":_vm.reloadRequested,"onDataUpdated":_vm.onDataUpdated,"filterTags":_vm.filterTags}}),_c('v-snackbar',{attrs:{"timeout":10000,"color":_vm.snackColor},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},[_vm._v("Понятно")])],1),_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.miniVariant,"app":"","clipped":"","right":""}},[_c('v-app-bar',{attrs:{"dense":"","flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.miniVariant = !_vm.miniVariant}}})],1),(!_vm.miniVariant)?_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Подитоги")]),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Сегодня")]),(
                !_vm.today_widget_totals.any_problems ||
                _vm.today_widget_totals.any_problems == 0
              )?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formatAmount(_vm.today_widget_totals.subtotal)))]):_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.today_widget_totals.any_problems)+" "+_vm._s(_vm.num2str(_vm.today_widget_totals.any_problems, [ "проблема", "проблемы", "проблем", ]))+" в транзакциях ")])],1)],1),(false)?_c('v-card-text',[(
              !_vm.today_widget_totals.any_problems ||
              _vm.today_widget_totals.any_problems == 0
            )?_c('v-chip',{staticClass:"d-block",attrs:{"color":_vm.getColor(_vm.today_widget_totals.subtotal),"text-color":"white","dense":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-scale-balance")])],1),_vm._v(" "+_vm._s(_vm.formatAmount(_vm.today_widget_totals.subtotal))+" ")],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticClass:"d-block",attrs:{"color":"grey","text-color":"white"}},on),[_vm._v(" "+_vm._s(_vm.formatAmount(_vm.today_widget_totals.subtotal))+" * ")])]}}],null,false,561537634)},[_c('span',[_vm._v(_vm._s(_vm.today_widget_totals.any_problems)+" "+_vm._s(_vm.num2str(_vm.today_widget_totals.any_problems, [ "проблема", "проблемы", "проблем", ]))+" в транзакциях")])])],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.forcast}},[_vm._v("Пересчитать прогноз")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }