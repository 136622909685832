<!--TODO: add expanded rows like this https://codepen.io/francobao/pen/mqxMKP  -->
<template>
  <v-container fluid>
    <v-row class="pt-4">
      <h2 class="headline">Транзакции</h2>
      <v-tooltip right v-if="editable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="primary"
            icon
            v-bind="attrs"
            v-on="on"
            @click.stop="add"
          >
            <v-icon large dark color="primary darken-2">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Добавить транзакцию</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Сдвинуть прошедшие неподтвержденные транзакции на текущий день -->
      <v-tooltip left v-if="editable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="primary"
            icon
            v-bind="attrs"
            v-on="on"
            @click.stop="shiftAllPastUnconfirmedTransactionToDate"
          >
            <v-icon dark color="primary darken-2">mdi-chevron-double-down</v-icon>
          </v-btn>
        </template>
        <span>Сдвинуть прошедшие неподтвержденные транзакции на текущий день</span>
      </v-tooltip>       
      <!-- Сдвинуть все неисполненные в прошлом транзакции на один день -->
      <v-tooltip left v-if="editable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="primary"
            icon
            v-bind="attrs"
            v-on="on"
            @click.stop="shiftAllUnconfirmedTransaction"
          >
            <v-icon dark color="red darken-2">mdi-chevron-double-down</v-icon>
          </v-btn>
        </template>
        <span>Сдвинуть все неисполненные в прошлом транзакции на один день</span>
      </v-tooltip>       
      <!-- Сформировать прогноз -->
      <v-tooltip left v-if="editable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="primary"
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="formula === undefined || formula.length == 0"
            @click.stop="forcast"
          >
            <v-icon dark color="primary darken-2">mdi-progress-clock</v-icon>
          </v-btn>
        </template>
        <span>Сформировать прогноз</span>
      </v-tooltip>
      <v-tooltip left v-if="editable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="primary"
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="formula === undefined || formula.length == 0"
            @click.stop="cleanForcast"
          >
            <v-icon dark color="primary darken-2">mdi-delete-sweep</v-icon>
          </v-btn>
        </template>
        <span>Очистить прогноз</span>
      </v-tooltip>
    </v-row>
    <v-row class="d-block pt-4">
      <v-data-table
        :headers="headers"
        :items="transactions"
        class="elevation-1"
        :disable-pagination="sheetView ? true : false"
        :hide-default-footer="sheetView ? true : false"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        :loading="loading"
      >
        <!--
        :search="search"
        :custom-filter="filterTransactions"
      -->
        <!--template v-slot:top>
          <v-text-field v-model="search" label="Поиск" class="mx-8"></v-text-field>
        </template-->
        
        <!-- Описание -->
        <template v-slot:item.description="props">
          <v-edit-dialog
            :return-value.sync="props.item.description"
            @save="save(props.item)"
            @cancel="cancel"
            @open="open(props.item)"
            @close="close"
            v-if="editable"
          >
            <v-sheet
              color="transparent"
              style="cursor: pointer"
              :class="{ 'font-italic': !!props.item.forcast }"
            >
              {{ props.item.description }} 
              <!-- количество связанных транзакций -->
              <span class="dark green--text text--darken-1">{{ props.item.calculation_refTrx?"+"+props.item.calculation_refTrx:"" }}</span>
            </v-sheet>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.description"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.date="props">
          <v-menu :close-on-content-click="true" :nudge-right="40" offset-y>
            <template v-slot:activator="{ on }">
              <v-sheet
                color="transparent"
                v-on="on"
                style="cursor: pointer"
                :class="{ 'font-italic': !!props.item.forcast }"
              >
                {{ getDisplayDate(props.item.date) }}
              </v-sheet>
            </template>
            <v-date-picker
              v-model="props.item.date"
              autoclose="true"
              color="primary lighten-1"
              first-day-of-week="1"
              locale="ru"
              no-title
              scrollable
              actions
              @input="save(props.item)"
              v-if="editable"
            >
            </v-date-picker>
          </v-menu>
        </template>
        <!-- Сумма -->
        <template v-slot:item.amount="{ item }" v-if="useChip">
          <v-edit-dialog
            :return-value.sync="item.amount"
            @save="save(item)"
            @cancel="cancel"
            @open="open(item)"
            @close="close"
            v-if="editable"
          >
            <v-chip class="mt-1" :color="getColor(item.amount)" dark>
              <v-sheet
                color="transparent"
                style="cursor: pointer"
                :class="{ 'font-italic': !!item.forcast }"
              >
                {{ formatAmount(item.amount) }}
              </v-sheet>
            </v-chip>
            <template v-slot:input>
              <v-text-field
                v-model="item.amount"
                :rules="[amountValidation]"
                label="Edit"
                single-line
                counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <v-chip class="mt-1" :color="getColor(item.amount)" dark v-else>
            {{ formatAmount(item.amount) }}
          </v-chip>
        </template>
        <template v-slot:item.amount="{ item }" v-else>
          <!-- v-else for useChip -->
          <div
            :class="
              getColor(item.amount) + '--text text--darken-2 font-weight-medium'
            "
          >
            {{ formatAmount(item.amount) }}
          </div>
        </template>
        <!-- Подитог (EBITDA) -->
        <template v-slot:item.subtotal_ebitda="{ item }">
          <div
            :class="getColor(item.subtotal_ebitda) + '--text text--darken-2'"
          >
            {{ formatAmount(item.subtotal_ebitda) }}
          </div>
        </template>
        <!-- Налоговая нагрузка -->
        <template v-slot:item.payloadtax="{ item }">
          <div :class="getColor(item.payloadtax) + '--text text--darken-2'">
            {{ item.payloadtax ? formatAmount(item.payloadtax) : "-" }}
          </div>
        </template>
        <!-- Комиссионная нагрузка -->
        <template v-slot:item.payloadcomission="{ item }">
          <div
            :class="getColor(item.payloadcomission) + '--text text--darken-2'"
          >
            {{
              item.payloadcomission ? formatAmount(item.payloadcomission) : "-"
            }}
          </div>
        </template>
        <!-- Статус -->
        <template v-slot:item.status="{ item }">
          <v-tooltip bottom v-if="item.calculation_result.warnings > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon style="color: red" v-bind="attrs" v-on="on"
                >mdi-alert-box</v-icon
              >
            </template>
            <span>Возникли проблемы при расчете формул</span>
          </v-tooltip>
          <div v-if="item.resolution == 'confirmed'">проведено</div>
          <div v-else>
            {{ item.resolution }}
          </div>
        </template>
        <!-- Подитог -->
        <template v-slot:item.subtotal="{ item }">
          <div :class="getColor(item.subtotal) + '--text text--darken-2'">
            {{ formatAmount(item.subtotal) }}
          </div>
        </template>
        <!-- Договор -->
        <template v-slot:item.contract.title="{ item }">
          <router-link
            class="font-weight-regular"
            style="text-decoration: none"
            :to="{ name: 'contract', params: { id: item.contract._id } }"
            >{{ item.contract.title }}</router-link
          >
          <!--v-btn
                text
                color="primary"
                :to="{ name: 'contract', params: { id: item.contract._id } }"
                small>{{ item.contract.title }}</v-btn
              -->
        </template>
        <!-- Действия -->
        <template v-slot:item.actions="{ item }">
          <!-- если операция подтврждена, то еще можно сделать ей undo, трижды нажав на галочку -->
          <v-icon
            style="color: green"
            v-if="item.resolution == 'confirmed'"
            @click="undoItemEnabled.push(item._id)"
            >mdi-checkbox-marked-outline</v-icon
          >
          <!-- Подтверждение транзакции -->
          <v-tooltip bottom v-if="!item.resolution && !item.forcast">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click.stop="confirm(item)"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-checkbox-marked-outline</v-icon>
              </v-btn>
            </template>
            <span>Подтвердить транзакцию</span>
          </v-tooltip>
          <!-- Зафиксировать прогнозную транзакцию -->
          <v-tooltip bottom v-if="!item.resolution && !!item.forcast">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click.stop="fix(item)"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pin</v-icon>
              </v-btn>
            </template>
            <span>Зафиксировать транзакцию</span>
          </v-tooltip>
          <!-- Убрать транзакцию -->
          <v-tooltip
            bottom
            v-if="
              !item.resolution ||
              undoItemEnabled.filter((x) => x == item._id).length > 2
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click.stop="decline(item)"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Убрать транзакцию</span>
          </v-tooltip>
          <!-- Скопировать -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click.stop="copy(item)"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Скопировать</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor"> <!-- todo make component!! and mixins -->
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { stringify } from "query-string";
import moment from "moment";

const API_URL_TRX = process.env.VUE_APP_BASE_API_URL + "/trx";

export default {
  name: "list-transaction",
  components: {},
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    // отображать колбасками (true) или по-простому (false)
    useChip: {
      type: Boolean,
      default: true,
    },
    headerType: {
      type: String,
      default: "short",
    },
    // table look likes a long sheet
    sheetView: {
      type: Boolean,
      default: false,
    },
    // формула, по которой должны создаваться автоматчиески транзакции (в формате JSON)
    formula: {
      type: String,
      default: "",
    },
    // дополнительный путь в запросе при чтении транзаций
    // (например, нужен для модификации запроса с целью отображения всех транзакций)
    // для отображения всех транзакций надо задать значение subQueryUrl="/display"
    subQueryUrl: {
      type: String,
      default: "",
    },
    // минимальная сумма транзакции, отображаемая в списке
    filterMinAmount: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    transactions: [],
    today_totals: {
      subtotal: 0,
      subtotal_ebitda: 0,
      subtotal_taxes: 0,
      subtotal_comission: 0,
      id_expanded: -1,
    },
    loading: false,
    expanded: [],
    singleExpand: false,
    //editDateDialog: {},
    undoItemEnabled: [],
    snack: false,
    snackColor: "",
    snackText: "",
    max25chars: (v) =>
      v.length <= 80 || "Сократите текст: максимально 80 символов",
    amountValidation: (v) =>
      /[-+]?\d{1,10}(\.\d{1,2})?/.test(v) || "Должна быть сумма",
    pagination: {},
    addNewEventMode: false,
    // two kinds of headers (short and full)
    // full is going with contract information
    headers: [
      {
        text: "Дата",
        align: "left",
        sortable: false,
        value: "date",
        width: "150px",
      },
      {
        text: "Сумма",
        align: "left",
        sortable: false,
        value: "amount",
        width: "150px",
      },
      {
        text: "Описание",
        align: "left",
        sortable: false,
        value: "description",
      },
      {
        text: "Действия", // действия доступны только в коротком режиме отображения, хотя можно добавить и в полной (full)
        align: "right",
        sortable: false,
        value: "actions",
      },
    ],
  }),
  methods: {
    getDisplayDate(d) {
      return moment(d).format("DD/MM/YYYY");
      // return new Date(d).toLocaleDateString(undefined, {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      // });
    },
    emit(eventName, value) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
    confirm(item) {
      // eslint-disable-next-line no-console
      console.log(`confirm(${item.id}`);
      // changes resolution and save
      item.resolution = "confirmed";
      this.save(item);
    },
    fix(item) {
      console.log(`fix(${item.id}`);
      // /trx/
      const params = {
        obj: this.$route.params.id,
        id: item._id,
      };
      return fetch(API_URL_TRX + "/forcast/fix", {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => { return response.ok?response:response.text().then((text) => { throw Error(text)}); }) // todo move to mixins
        .then((response) => response.json())
        .then((result) => {
          console.log("response is ", result);
          
          // this.snack = true;
          // this.snackColor = "primary";
          // this.snackText = "Добавлено траназакицй " + result.length;

          this.reload();
        })
        .catch((error) => { // todo move to mixins
          this.snack = true;
          this.snackColor = "error";
          this.snackText = error;
        });
    },
    // !!! генерирует прогнозные транзакции
    forcast() {
      const params = {
        obj: this.$route.params.id,
        formula: this.formula,
      };
      return fetch(API_URL_TRX + "/forcast", {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => { return response.ok?response:response.text().then((text) => { throw Error(text)}); }) // todo move to mixins
        .then((response) => response.json())
        .then((result) => {
          console.log("response is ", result);
          
          this.snack = true;
          this.snackColor = "primary";
          this.snackText = "Добавлено траназакицй " + result.length;

          this.reload();
        })
        .catch((error) => { // todo move to mixins
          this.snack = true;
          this.snackColor = "error";
          this.snackText = error;
        });
    },
    cleanForcast() {
      const params = {
        obj: this.$route.params.id,
      };
      return fetch(API_URL_TRX + "/forcast", {
        method: "DELETE",
        body: JSON.stringify(params),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          console.log("response is ", result);
          this.reload();
        });
    },
    /**
     * Сдвигает все неподтвержденные транзакции на один день
     */
    shiftAllUnconfirmedTransaction () {
      const params = {
        obj: this.$route.params.id,
        days: 1,
        allForecastTransaction: true
      };
      return fetch(API_URL_TRX + "/shiftall", {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          console.log("response is ", result);
          this.reload();
        });
    },   
    shiftAllPastUnconfirmedTransactionToDate() {
      const params = {
        obj: this.$route.params.id,
      };
      return fetch(API_URL_TRX + "/shiftall", {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          console.log("response is ", result);
          this.reload();
        });
    },
    update(trx) {
      console.log(`onUpdateTrxItem(${JSON.stringify(trx)})`);
      // link to current object
      trx.obj = this.$route.params.id;
      // make post request
      return fetch(API_URL_TRX, {
        method: "POST",
        body: JSON.stringify(trx),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          console.log("response is ", result);
          // add new message on a page
          if (result.details == null) {
            // is this update already exists object
            var trxUpdated = this.transactions.find(
              (x) => x._id && x._id == result._id
            );
            console.log("trxUpdated is", trxUpdated || "n/a");
            // is anything to be updated?
            if (trxUpdated) {
              console.log("updating existed item");
              trxUpdated = result.details; // yes!
            } else {
              // nothing to update, so this trx is new one
              console.log("add new item");
              // add
              this.transactions.push(result);
              return result;
            }
            // this.item = result;
            // finish editing
            // this.itemEditing.customerrate = false;
          }
        });
    },
    reload() {
      // eslint-disable-next-line no-console
      console.log("(re)load transactions");
      return fetch(
        `${API_URL_TRX}${this.subQueryUrl}?${stringify(this.$route.params)}`
      )
        .then((resp) => resp.json())
        .then((resp_json) => {
          // eslint-disable-next-line no-console
          console.log(resp_json);
          //~~~this.transactions.push.apply(this.transactions, resp_json);
          if (resp_json.hasOwnProperty("trxs")) {
            //this.transactions = resp_json.trxs;
            this.transactions = resp_json.trxs;
            this.today_totals = resp_json.today_totals;
          } else {
            this.transactions = resp_json;
            //this.transactions.push.apply(this.transactions, resp_json);
          }
        });
    },
    decline(item) {
      console.log(`decline(${item.id}`);
      // changes resolution and save
      item.obj = this.$route.params.id;
      item.resolution = "declined";
      // make delete request
      return fetch(API_URL_TRX, {
          method: "DELETE",
          body: JSON.stringify(item),
          headers: {
            "content-type": "application/json"
          }
        })
        .then((resp) => resp.json())
        .then((result) => console.log("response is ", result))
        .then(() => {
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Транзакция удалена";
          this.reload();
        });
    },
    add() {
      console.log("Add new trx");
      let newTrx = {
        date: new Date().toISOString(),
        amount: 0,
        description: "<новая транзакция>",
      };
      this.update(newTrx).then((item) => {
        //this.editDateDialog[item._id] = false;
      });
    },
    copy(item) {
      console.log("Copy trx", item);
      let newTrx = {
        date: item.date,
        amount: item.amount,
        description: "(Копия) " + item.description,
      };
      this.update(newTrx).then((item) => {
        console.log("Copied!");
      });
    },
    save(item) {
      // eslint-disable-next-line no-console
      console.log("Save trx " + item._id);
      // save
      this.update(item).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Сохранено!";
        // close the dialog
        //this.editDateDialog[item._id] = false;
        // reload all
        this.reload();
      });
      //this.editDateDialog123 = false;
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Отмена";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Редактируем";
    },
    close() {
      // eslint-disable-next-line no-console
      console.log("Dialog closed");
    },
    getColor(amount) {
      if (amount < 0) return "red";
      else if (amount > 100000) return "green";
      else return "orange";
    },
    formatAmount(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      let valFormated = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return value > 0 ? "+" + valFormated : valFormated;
    },
    onCancelEditEvent() {
      // eslint-disable-next-line no-console
      console.log(`onCancelEditEvent()`);
      //
      this.selectedEvent = -1;
    },
    // ,
    // filterTransactions(value, search, item) {
    //   return (
    //     value != null &&
    //     item != null &&
    //     search != null &&
    //     typeof value === "string" &&
    //     value
    //       .toString()
    //       .toLocaleUpperCase()
    //       .indexOf(search.toLocaleUpperCase()) !== -1
    //   );
    // }
  },

  mounted() {
    this.loading = true;
    this.reload().then(()=>{this.loading = false});
  },
  created() {
    /*???
    this.transactions.map(x => {
      this.editDateDialog[x._id] = false;
      // eslint-disable-next-line no-console
      console.log("prep calendal " + x._id);
    });
    */
  },
  computed: {},
};
</script>
